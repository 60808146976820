import React, { useState, useMemo } from "react";
import { Row, Col, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header: React.FC = () => {
  const [visible, setVisibility] = useState(false);
  const { t } = useTranslation();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = useMemo(() => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id);
      element?.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  }, [t]);

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="" height="" />
          </LogoContainer>
          <NotHidden>
            {MenuItem}
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer
          closable={false}
          open={visible}
          onClose={toggleButton}
          title={<Menu>{t("Menu")}</Menu>}
        >
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          {MenuItem}
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default React.memo(Header);
// src/router/config.ts
const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "/partners",
    exact: true,
    component: "Partners",
  },
  {
    path: "/news",
    exact: true,
    component: "News",
  },
  {
    path: "/press",
    exact: true,
    component: "Press",
  },
  {
    path: "/team",
    exact: true,
    component: "Team",
  },
];

export default routes;
// src/components/Footer/styles.ts
import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled.footer`
  background: rgb(241, 242, 243);
  padding: 2.5rem 0;
`;

export const Title = styled.h4`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;
  margin-bottom: 1rem;
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;

export const Extra = styled.section`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  position: relative;
`;

export const Para = styled.div`
  color: #18216d;
  font-size: 14px;
  width: 70%;
`;

export const Large = styled(Link)`
  font-size: 16px;
  color: #18216d;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  &:hover {
    color: rgb(255, 130, 92);
  }
`;

export const Chat = styled.a`
  color: #18216d;
  max-width: fit-content;
  border-bottom: 1px solid #18216d;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-bottom: 1px solid rgb(255, 130, 92);
    color: rgb(255, 130, 92);
  }
`;

export const Language = styled.h4`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;
  display: block;
  margin-bottom: 1rem;
  font-weight: bold; // Make the label bold
`;

export const LanguageSwitch = styled.div`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

export const FooterContainer = styled.div`
  max-width: 510px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  a {
    &:hover,
    &:active,
    &:focus {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
`;

export const Hidden = styled.div`
  display: contents;
  width: 100%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FooterSubRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Empty = styled.div`
  width: 100%;
`;